import logo from './logo.svg';
import './App.css';
import UploadComponent from './UploadComponent';
import PDFViewer from './PDFViewer';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <UploadComponent />
      </header>


      {/* <Router>
<div className="App">


<Routes>
<Route path="/UploadComponent" element={<UploadComponent />} />
<Route path="/PDFViewer" element={<PDFViewer />} />


<Route path="/" element={<Navigate replace to="/UploadComponent" />} />
</Routes>        
</div>
</Router> */}


    </div>





  );
}

export default App;
