import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { Box, Button, List, ListItem, ListItemText, Grid, Paper, Typography, Divider } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Cancel as CancelIcon, GetApp as GetAppIcon, Visibility as VisibilityIcon } from '@mui/icons-material';

import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none',
});

const UploadComponent = () => {
    const [file, setFile] = useState(null);
    const [qrCode, setQrCode] = useState('');
    const [recentUploads, setRecentUploads] = useState([]);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setFile(acceptedFiles[0]);
        setUploadSuccess(false); // Reset the upload success flag
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);
        
 // Construct the endpoint using window.location
 const protocol = window.location.protocol;
 const hostname = window.location.hostname;
 const port = 3000; // If your server port is always the same as the client port
//  const endpoint = `${protocol}//${hostname}:${port}/upload`;

 const endpoint = `https://backend-dot-faizbackendproject.de.r.appspot.com/upload`;

        // // Update this endpoint as needed
        // const endpoint = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/upload`;

        try {
            const response = await axios.post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setQrCode(response.data.qrCode);
            setRecentUploads([...recentUploads, { name: file.name, qrCode: response.data.qrCode, fileUrl: response.data.fileUrl }]);
            setUploadSuccess(true);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleCancel = () => {
        setFile(null);
        setQrCode('');
    };

    const handleDownloadQRButton = () => {
        // Function to trigger download of QR code image
        const link = document.createElement('a');
        link.href = qrCode;
        link.download = 'QRCode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadQR = (qrCodeUrl) => {
        if (!qrCodeUrl) return;
      
        const link = document.createElement('a');
        link.href = qrCodeUrl;
        link.download = 'QRCode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      

    const handleViewFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };
    return (
      

        <Box sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 4, textAlign: 'center' }}>
            NSCL File-to-QR
        </Typography>
        
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2, minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Box {...getRootProps()} sx={{ border: '1px dashed grey', p: 2, textAlign: 'center' }}>
                        <input {...getInputProps()} />
                        <Typography>Drag 'n' drop a file here, or click to select a file</Typography>
                    </Box>
                    {file && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                            <Typography noWrap>{file.name}</Typography>
                            <CancelIcon onClick={handleCancel} sx={{ cursor: 'pointer',color: 'red'  }} />
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUpload}
                        disabled={!file || uploadSuccess}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Upload
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
                <Paper elevation={3} sx={{ p: 2, minHeight: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
                        QR code
                    </Typography>
                    {qrCode && (
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={qrCode} alt="Generated QR Code" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<GetAppIcon />}
                        onClick={handleDownloadQRButton}


                        // onClick={() => handleDownloadQR(upload.qrCode)}
                        fullWidth
                        sx={{ mt: 2, backgroundColor: '#4CAF50', '&:hover': { backgroundColor: '#388E3C' } }}
                    >
                        Download QR
                    </Button>
                </Paper>
            </Grid>
        </Grid>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
            Recent Uploads
        </Typography>
        <List>
            {recentUploads.map((upload, index) => (
                <ListItem key={index} disablePadding sx={{bgcolor: '#495877',p: 1.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <ListItemText primary={upload.name} />
                    <Box sx={{ bgcolor: '#495877', p: 1.5, color: 'white' }}>
                        <Button
                            variant="contained"
                            startIcon={<VisibilityIcon />}
                            onClick={() => handleViewFile(upload.fileUrl)}
                            sx={{ mr: 1 }}
                        >
                            View
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<GetAppIcon />}
                            // onClick={handleDownloadQR}
                            onClick={() => handleDownloadQR(upload.qrCode)}
                            sx={{ backgroundColor: '#4CAF50', '&:hover': { backgroundColor: '#388E3C' } }}
                        >
                            Download QR
                        </Button>
                    </Box>
                </ListItem>
            ))}
        </List>
    </Box>
    );
};

export default UploadComponent;
