import React from 'react';
import PDF from 'react-pdf-js';

class PDFViewer extends React.Component {
  state = {
    page: 1,
    pages: null,
  };

  onDocumentComplete = (pages) => {
    this.setState({ pages });
  }

  onDocumentError = (error) => {
    console.error('Error while loading document:', error);
  }

  handlePrevious = () => {
    this.setState(prevState => ({
      page: prevState.page > 1 ? prevState.page - 1 : 1
    }));
  }

  handleNext = () => {
    this.setState(prevState => ({
      page: prevState.page < prevState.pages ? prevState.page + 1 : prevState.pages
    }));
  }

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className={`previous ${page === 1 ? 'disabled' : ''}`} onClick={this.handlePrevious}>
        <button><i className="fa fa-arrow-left"></i> Previous</button>
      </li>
    );
    let nextButton = (
      <li className={`next ${page === pages ? 'disabled' : ''}`} onClick={this.handleNext}>
        <button><i className="fa fa-arrow-right"></i> Next</button>
      </li>
    );
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  }

  render() {
    let pagination = null;
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }

    return (
      <div>
        <PDF
          file={this.props.fileUrl}
          onDocumentComplete={this.onDocumentComplete}
          onDocumentError={this.onDocumentError}
          page={this.state.page}
        />
        {pagination}
      </div>
    );
  }
}

export default PDFViewer;
